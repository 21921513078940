import type { GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'
import { AuthPanel } from '../modules/AuthPanel/AuthPanel'
import { NextPageWithLayout } from './_app'
import Layout from '~/modules/Layout/Layout'
import { getStaticPropsNext } from '~/next-modules'

const LoginPage: NextPageWithLayout = () => {
  return (
    <>
      <NextSeo title="Login" />
      <div className="pt-[var(--header-padding)]">
        <AuthPanel />
      </div>
    </>
  )
}

LoginPage.getLayout = (page) => <Layout>{page}</Layout>

const getStaticProps: GetStaticProps = getStaticPropsNext({})
export { getStaticProps }

export default LoginPage
